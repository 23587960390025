<template>
  <div class="container mt-5">
    <h1>JIRA Time Tracking</h1>
    <div class="form-group">
      <label for="days">Days since JIRA created:</label>
      <input
        id="days"
        type="number"
        class="form-control"
        v-model.number="days"
      />
      <button class="btn btn-primary mt-2" @click="fetchTimeTrackingData">Submit</button>
    </div>
    <div v-if="loading" class="text-center">
      <p>Loading data...</p>
    </div>
    <div v-else>
      <div v-if="Object.keys(timeTrackingData).length === 0">
        <p>No time tracking data found.</p>
      </div>
      <div v-else>
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th>EPIC Key</th>
              <th>Custom Field Value</th>
              <th>Total Time Spent (hours)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, key) in timeTrackingData" :key="key">
              <td>{{ key }}</td>
              <td>{{ data.custom_field_value }}</td>
              <td>{{ data.total_time_spent_hours.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
const fetch = require('node-fetch');

export default {
  data() {
    return {
      timeTrackingData: {},
      loading: true,
      days: 120, // Default value for the number of days since JIRA issue creation
    };
  },
  mounted() {
    this.fetchTimeTrackingData();
  },
  methods: {
    async fetchTimeTrackingData() {
      this.loading = true; // Show loading indicator

      // Replace these with your JIRA details
      const apiUrl = process.env.VUE_APP_JIRA_URL;
      const username = process.env.VUE_APP_JIRA_USERNAME;
      const apiToken = process.env.VUE_APP_JIRA_API_TOKEN;

      // Prepare the JQL query using the dynamic `days` value
      const jql_query = `project=CART AND issuetype=Epic AND component="New Carrier" AND resolution not in ("Won't Do", "Won't Fix", Rejected, Duplicate) AND created >= -${this.days}d`;

      // URL encode the JQL query using encodeURIComponent
      const encoded_jql_query = encodeURIComponent(jql_query);

      // Define the search URL
      const search_url = `${apiUrl}/rest/api/3/search?jql=${encoded_jql_query}&fields=timetracking,customfield_10378`;

      try {
        // Use btoa to encode the credentials to base64
        const credentials = btoa(`${username}:${apiToken}`);

        const response = await fetch(search_url, {
          method: 'GET',
          headers: {
            'Authorization': `Basic ${credentials}`,
            'Accept': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        const epics = data.issues;
        let epic_data = {};

        for (const epic of epics) {
          const epic_key = epic.key;
          let epic_time_spent = 0;

          const fields = epic.fields;
          const custom_field = fields.customfield_10378 || {};
          const custom_field_value = typeof custom_field === 'object' ? custom_field.value || 'N/A' : custom_field;

          // Add the EPIC's own time spent
          const time_tracking = fields.timetracking || {};
          epic_time_spent += time_tracking.timeSpentSeconds || 0;

          // Fetch child issues for the EPIC
          const child_search_url = `${apiUrl}/rest/api/3/search?jql=parent=${epic_key}&fields=timetracking`;

          const childResponse = await fetch(child_search_url, {
            method: 'GET',
            headers: {
              'Authorization': `Basic ${credentials}`,
              'Accept': 'application/json',
            },
          });

          if (!childResponse.ok) {
            throw new Error(`Error: ${childResponse.status} - ${childResponse.statusText}`);
          }

          const childData = await childResponse.json();
          const child_issues = childData.issues;

          for (const issue of child_issues) {
            const child_fields = issue.fields;

            // Add up the time spent for the child issues
            const child_time_tracking = child_fields.timetracking || {};
            const child_time_spent = child_time_tracking.timeSpentSeconds || 0;
            epic_time_spent += child_time_spent;
          }

          epic_data[epic_key] = {
            'total_time_spent_hours': epic_time_spent / 3600,
            'custom_field_value': custom_field_value,
          };
        }

        this.timeTrackingData = epic_data;

      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false; // Hide loading indicator
      }
    },
  },
};
</script>

<style>
.container {
  max-width: 800px;
}

.table {
  margin-top: 20px;
}
</style>
