<template>
  <div id="app">
    <TimeTracking />
  </div>
</template>

<script>
import TimeTracking from './components/TimeTracking.vue';

export default {
  name: 'App',
  components: {
    TimeTracking,
  },
};
</script>
